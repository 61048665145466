<!-- 精准教学 -->
<template>
  <div class="jzjx_div">
    <div class="top-img">
      <img src="static/lvya/jzjx/01.png" alt="">
    </div>
    <div class="module module-01">
      <div class="w1200 flex_left_right">
        <div class="left">
          <div class="tip-text-01">
            <p class="title">大数据精准教学</p>
            <p class="line"></p>
          </div>
          <p class="lineh30">大数据精准教学系统深度挖掘数据价值，常态化采集学生的日常作业、各项测评数据及课堂等多元场景学情数据，结合“AI+大数据”精细分析技术，分析学生常态化学情，实现大数据驱动学校科学管、教师精准教、学生个性学，帮助学校管理者实时掌握学情动态，优化教学与管理策略，稳步提升学校整体教学质量。</p>
          <ul class="ul01">
            <li>
              <img src="static/lvya/jzjx/22.png" alt="">
              <p>全学段、全学科支持</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/23.png" alt="">
              <p>作业/考试/课堂全场景覆盖</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/24.png" alt="">
              <p>立体化/多维度学情分析</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/25.png" alt="">
              <p>个性化智能教与学</p>
            </li>
          </ul>
        </div>
        <div class="right">
          <ul>
            <li>
              <img src="static/lvya/jzjx/16.png" alt="">
              <p class="p1">教学平台</p>
              <p class="p2">提供云端应用支持</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/17.png" alt="">
              <p class="p1">作业系统</p>
              <p class="p2">常态化日常学情采集</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/18.png" alt="">
              <p class="p1">考试系统</p>
              <p class="p2">阶段学情监测</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/19.png" alt="">
              <p class="p1">题库系统</p>
              <p class="p2">校本题库应用与建设</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/20.png" alt="">
              <p class="p1">智慧课堂</p>
              <p class="p2">数据驱动课堂</p>
            </li>
            <li>
              <img src="static/lvya/jzjx/21.png" alt="">
              <p class="p1">学业质量检测与诊断</p>
              <p class="p2">数据助力教育质量精准提升</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="module module-02" id="jxpt">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">教学平台</p>
          <p class="line"></p>
          <p class="text">以资源为基础，以技术为创新，融合课前、课中、课后及课外等场景，为教育教学活动提供优质题库资源、组卷系统、校本题库、在线备课、错题本、作业、学情分析等系统，可为云作业、AI课后作业、校内测评、校内考试提供云端应用支持。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/jzjx/02.png?v=1.0" alt="">
      </div>
		</div>
    <div class="module module-03">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">作业系统</p>
          <p class="line"></p>
          <p class="text">作业系统基于行业领先的大数据学情分析系统、人工智能技术、海量优质精品题库资源和信息化智能终端，以常态化作业数据采集、评价、反馈、改进为抓手，开展作业质量全过程评估，有效提升作业设计质量。应用信息化智能终端，实现作业批改“零”负担；多维度大数据学情分析，帮助教师和学校管理者优化教学设计、提升教学效率。</p>
        </div>
        <div class="demo_warp">
          <ul class="tab_tit">
            <li :class="n==1?'active':''" @click="n=1">
              <div class="left">
                <img src="static/lvya/jzjx/11.png" alt="" v-if="n==1">
                <img src="static/lvya/jzjx/10.png" alt="" v-else>
              </div>
              <div class="right">
                <p class="p1">AI课后作业</p>
                <p class="p2">通过高速扫描仪一键扫描上传</p>
              </div>
              <span class="span" v-if="n==1"></span>
            </li>
            <li :class="n==2?'active':''" @click="n=2">
              <div class="left">
                <img src="static/lvya/jzjx/13.png" alt="" v-if="n==2">
                <img src="static/lvya/jzjx/12.png" alt="" v-else>
              </div>
              <div class="right">
                <p class="p1">答题器作业</p>
                <p class="p2">学生通过答题器答题</p>
              </div>
              <span class="span" v-if="n==2"></span>
            </li>
            <li :class="n==3?'active':''" @click="n=3">
              <div class="left">
                <img src="static/lvya/jzjx/15.png" alt="" v-if="n==3">
                <img src="static/lvya/jzjx/14.png" alt="" v-else>
              </div>
              <div class="right">
                <p class="p1">在线答题</p>
                <p class="p2">学生在线答题，在线采集作业数据</p>
              </div>
              <span class="span" v-if="n==3"></span>
            </li>
          </ul>
          <div class="tab_con">
            <div v-show="n==1">
              <img src="static/lvya/jzjx/05.png" alt="">
            </div>
            <div v-show="n==2">
              <img src="static/lvya/jzjx/03.png" alt="">
            </div>
            <div v-show="n==3">
              <img src="static/lvya/jzjx/04.png" alt="">
            </div>
          </div>
        </div>
      </div>
		</div>
    <div class="module module-04">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">考试系统</p>
          <p class="line"></p>
          <p class="text">考试系统专门针对学校考试而研发，集答题卡制作、答题卡高速扫描和自动批阅、异常纠错机制、学情/考情综合分析、报表智能输出等功能于一体，大大节省考务成本，同时保证阅卷的准确率和效率。力求通过对核心的考试数据的精准分析诊断，靶向定位学生的知识盲点，精准化教学，驱动课堂内的因材施教成为现实。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/jzjx/06.png?v=1.0" alt="">
      </div>
		</div>
    <div class="module module-05">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">智慧课堂</p>
          <p class="line"></p>
          <p class="green-text">打造新型智慧教学环境  优化课堂教学过程   数据驱动课堂生态变革</p>
          <p class="text">AI智慧课堂，以教学云平台为依托，以学生便携式智能终端为载体，立足课堂这个核心场景，整合智能终端、教学应用和优质资源，构建课前、课中、课后完整的智慧教学环境；并在全量采集师生过程性数据的基础上，结合人工智能、大数据技术的应用，打造基于动态学习数据分析的智能、高效、互动课堂，优化课堂教学过程，实现数据驱动课堂生态变革。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/jzjx/07.png" alt="">
      </div>
		</div>
    <div class="module module-06">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">题库系统</p>
          <p class="line"></p>
          <p class="text">覆盖小初高200多个版本，1500多册教材，匹配到具体章节，内含精品同步题目、校本题目、校本试卷及教师个人试题试卷库，高效满足教师试题试卷管理与组题应用需求，并支持将学校教育教学活动中生成的试题试卷进行盘活沉淀，促进校本题库建设。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/jzjx/08.png" alt="">
      </div>
		</div>
    <div class="module module-07">
			<div class="w1200">
        <div class="tip-text">
          <p class="title">学业质量监测与诊断</p>
          <p class="line"></p>
          <p class="text">多维度学情报告，学校学情、班级学情、学生学情。</p>
        </div>
        <img class="img wow animate__fadeInUp" src="static/lvya/jzjx/09.png" alt="">
      </div>
		</div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  props: {},
  components: {
  },
  data() {
    return {
      n: 1,
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animate__animated',
				offset: '0',
				mobile: true,
				live: true
      })
      wow.init()
    })
  }
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.jzjx_div{
  width: 100%;
  min-width: 1200px;
  .top-img{
    line-height: 0;
    img{
      width: 100%;
      min-width: 1200px;
    }
  }
  .module{
    width: 100%;
    min-height: 200px;
    .w1200{
      width: 1200px;
      min-height: 100px;
      margin: 0 auto;
      padding: 50px 0 20px 0;
      box-sizing: border-box;
      .tip-text{
        text-align: center;
        .title{
          color: #222222;
          font-size: 30px;
          font-weight: bold;
        }
        .line{
          width: 63px;
          height: 5px;
          background: #19CA81;
          margin: 15px auto;
        }
        .text{
          font-size: 16px;
          color: #696969;
          line-height: 28px;
        }
        .green-text{
          color: #0CC579;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .w1090{
          width: 1090px;
          margin: 0 auto;
        }
      }
      .tip2-text{
        margin-top: 15px;
        .title{
          width: 1060px;
          margin: 0 auto;
          display: flex;
          align-content: center;
          img{
            width: 21px;
            height: 21px;
            margin-right: 10px;
            margin-top: 2px;
          }
          p{
            font-size: 18px;
            color: #222222;
            font-weight: bold;
          }
        }
        .text2{
          font-size: 16px;
          color: #696969;
          margin-top: 15px;
          line-height: 28px;
        }
        .w1060{
          width: 1060px;
          margin: 0 auto;
          margin-top: 15px;
        }
      }
    }
    .flex_left_right{
      width: 1200px;
      min-height: 100px;
      margin: 0 auto;
      padding: 50px 0 20px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .left{
        width: 45%;
        .tip-text-01{
          text-align: left;
          .title{
            color: #222222;
            font-size: 30px;
            font-weight: bold;
          }
          .line{
            width: 63px;
            height: 5px;
            background: #19CA81;
            margin-top: 15px;
          }
        }
        .lineh30{
          line-height: 36px;
          color: #666;
          margin-top: 20px;
        }
        .ul01{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-block-start: 0em;
          margin-block-end: 0em;
          padding-inline-start: 0px;
          margin-top: 30px;
          li{
            width: 50%;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img{
              margin-right: 10px;
            }
          }
        }
      }
      .right{
        width: 48%;
        ul{
          width: 100%;
          margin-block-start: 0em;
          margin-block-end: 0em;
          padding-inline-start: 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            width: 30%;
            padding: 20px;
            box-sizing: border-box;
            text-align: center;
            margin-bottom: 24px;
            .p1{
              font-size: 16px;
              font-weight: 600;
              color: #333;
              margin-top: 10px;
            }
            .p2{
              font-size: 14px;
              color: #666;
              margin-top: 6px;
            }
          }
          li:nth-child(1) {
            background: #FDFDEF;
          }
          li:nth-child(2) {
            background: #EEFEF8;
          }
          li:nth-child(3) {
            background: #FDF7F6;
          }
          li:nth-child(4) {
            background: #F1F7FF;
          }
          li:nth-child(5) {
            background: #ECF8FF;
          }
          li:nth-child(6) {
            background: #E9FFFD;
          }
        }
      }
    }
    .img{
      width: 100%;
      margin-top: 60px;
    }
  }
  .module-02,.module-04,.module-06{
    background: #fbfbfb;
    padding-bottom: 40px;
  }
  .module-03{
    .demo_warp{
      display: flex;
      margin-top: 40px;
      .tab_tit {
        width: 350px;
        min-height: 50px;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding-inline-start: 0px;
        li{
          min-height: 40px;
          display: flex;
          padding: 30px;
          box-sizing: border-box;
          border-radius: 5px;
          margin-bottom: 30px;
          background: #F4F6F9;
          align-items: center;
          position: relative;
          cursor: pointer;
          .left{
            margin-right: 20px;
          }
          .right{
            .p1{
              color: #333;
              font-size: 18px;
              font-weight: 600;
            }
            .p2{
              font-size: 14px;
              color: #666;
              margin-top: 10px;
            }
          }
          .span{
            display: block;
            // opacity: 0;
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid #19CA81;
            position: absolute;
            right: -40px;
            top: 50%;
            margin-top: -12px;
          }
        }
      }
      .active {
        color:#fff !important;
        background-color: #19CA81 !important;
        .right{
          .p1,.p2{
            color: #fff !important;
          }
        }
      }
      .tab_con {
        flex: 1;
        text-align: center;
        margin-left: 50px;
        div{
          img{
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
